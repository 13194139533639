.scraping-manager-wrap, .task-status-wrap, .task-status{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.task-status-wrap{
  width: 50%;
  .task-title-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2em;
  }
  .task-progress{
    width: 100%;
  }
  .task-step-description{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
