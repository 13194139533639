.incident-card-outer{
  display: block;
  width: 50vw;

  .incident-card-title{
    width: 100%;
    font-size: 1.5em;
  }
  .incident-card-inner{
    box-sizing: border-box;
    width: 100%;
    padding: 2em;
    display: flex;
  }
}

.card-object-properties-wrap{
  display: flex;
  gap: 2em;
  max-width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  .card-object-properties-wrap{
    padding: 0.5em;
    background-color: #007FFF22;
  }
}

.card-leaf-data-wrap {
  width: calc(50% - 1em);
  .fill-width-util{
    width: 100%;
    box-sizing: border-box;
  }
  .sources-adornment{
    position: absolute;
    top: -0.75em;
    left: -0.25em;
  }
}

.wide-field{
  width: 100%;
}

.sources-pop-over{
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 300px;
  direction: rtl;
  .card-leaf-data-wrap{
    width: 100%;
  }
  .card-object-properties-wrap{
    padding: 1em;
    box-sizing: border-box;
  }
  .sources-adornment{
    display: none;
  }
}

.preview-workspace{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
  direction: ltr;
}
.relative-nav-button-wrap{
  position: relative;
  padding: 0 3em;
  width: 7em;
  box-sizing: border-box;
  .fixed-nav-button-wrap{
    position: fixed;
    svg{
      font-size: 1.5em;
    }
  }
}
.incident-loader-wrap{
  margin: 1em auto;
  text-align: center;
}
.incident-error{
  direction: ltr;
  text-align: left;
  padding: 2em 1em;
  font-weight: bold;
  color: #888;
}

.tooltip-with-input{
  z-index: 1000 !important;
}

.merge-suggestions-wrap{
  padding: 2em;
}

@media (max-width: 768px) {
    .incident-card-outer{
        width: 100vw;
    }
    .card-leaf-data-wrap {
        width: 100%;
    }
}