.comments-section-wrap {
  width: 100%;

  .threads-area-accordion {
    width: 100%;
  }

  .threads-list {
    width: 50%;
    margin: 0 auto;

    .thread-card {
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      background-color: lightblue;

      .thread-status-change-button {
        background-color: white;
      }

      .thread-comments-wrap {
        width: 100%;

        .comment-card {
          max-width: 70%;
          min-width: 30%;
          box-sizing: border-box;
          padding: 1em;
          background-color: white;
          border-radius: 10px;

          .comment-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
            font-size: 0.8em;

            .comment-author {
              font-weight: bold;
            }

            .comment-date {
              color: grey;
            }
          }
        }
        .edit-comment{
          width: 100%;
        }

        @media (max-width: 768px) {
          .comment-card {
            max-width: 100%
          }
        }

        .my-comment {
          align-self: flex-start;
          border-top-right-radius: 0;
        }

        .other-comment {
          align-self: flex-end;
          border-top-left-radius: 0;
        }
      }
    }

    .thread-card.approved-thread {
      background-color: lightgreen;
    }

    .thread-card.dismissed-thread {
      background-color: lightcoral;
    }
  }

  @media (max-width: 768px) {
    .threads-list {
      width: 100%
    }
  }
}

