.modal-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content{
    background-color: #FAFAFA;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
}

.rnd-modal-wrap {
    position: fixed;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 1200;
}


.rnd-modal-content{
    background-color: #FAFAFA;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    pointer-events: all;
}