@import "../../lib/variables";
.alert-wrapper{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.alert-background{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(82, 86, 89, 0.75);
  z-index: 0;
}

.alert-background.opaque{
  background-color: rgb(82, 86, 89);
}

.alert {
  position: relative;
  margin: 10vh auto;
  width: 50%;
  max-height: 80vh;
  color: white;
  background-color: $purple-main;
  padding: 2.5%;
  z-index: 1;
  box-shadow: 0 1px 5px 0 black;
}

.alert a:link {
  color: #fff;
}

.alert a:visited {
  color: #fff;
}

.alert a:hover {
  color: #fff;
}

.alert a:active {
  color: #fff;
}

.alert-options-wrap{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
}

.alert-option{
  padding: 0.5em 2em;
  color: white;
  font-size: 1.25em;
  outline: none;
  transition: background-color 0.2s;
}

.alert-option:focus {
  background-color: $purple-dark;
  box-shadow: 1px 1px 9px -2px $purple-darker inset;
}

.alert-option:hover {
  background-color: $purple-dark;
  box-shadow: 2px 2px 15px -2px $purple-darker inset;
}

.alert-option:active {
  background-color: $purple-dark;
  box-shadow: 4px 4px 35px -2px $purple-darker inset;
}

@media only screen and (max-width: 480px){
  .alert{
    width: 85%;
  }
}