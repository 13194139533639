.empty-col-data{
  color: #ccc;
  text-align: center;
}
.table-cell{
  padding: 0.2em;
}
.conflicting-reports-cell{
  background-color: #ffaaaa;
}
.conflicting-reports-warning{
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #d32f2f;
  margin: 0.25em 0.5em;
  align-self: start;
  flex-basis: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}