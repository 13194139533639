$purple-main: #282c34;
$purple-dark: #181c24;
$purple-darker: #080c14;
$purple-light: #f8ccff;
$pink: rgb(255, 56, 131);
$cyan-main: rgb(0, 150, 136);
$cyan-dark: rgb(0, 130, 116);
$cyan-darker: rgb(0, 120, 96);
$cyan-highlight: rgba(0, 150, 136, 0.3);
$red-main: #dd0000;
$red-dark: #aa0000;
$red-darker: #880000;
$red-light: #ff5555;
$purple-main-trans_01: #282c3410;
$purple-main-trans_02: #282c3420;
$purple-main-trans_03: #282c3430;
$purple-main-trans_04: #282c3440;
$purple-main-trans_05: #282c3450;
$purple-main-trans_06: #282c3460;
$purple-main-trans_07: #282c3470;
$purple-main-trans_08: #282c3480;
$purple-main-trans_09: #282c3490;