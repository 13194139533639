.source-preview-draggable-wrap{
  height: 0;
  width: 0;
  overflow: visible;
  position: relative;
  z-index: 100;


  .handle {
    cursor: move;
    padding: 10px;
    background-color: #ccc;
    z-index: 1000;
    pointer-events: all;
  }
  /* Custom styles to visualize handles */
  .react-resizable-handle {
    position: absolute;
    z-index: 1000;
    pointer-events: all;
  }
  .draggable-resizable-box {
    position: relative;
  }

  .react-resizable-handle-se { cursor: se-resize; width: 20px; height: 20px; right: 0; bottom: 0; }
  .react-resizable-handle-e { cursor: e-resize; width: 10px; height: 100%; right: 0; top: 0; transform: none;  background: none;}
  .react-resizable-handle-s { cursor: s-resize; width: 100%; height: 10px; left: 0; bottom: 0;  transform: none; background: none;}
  .react-resizable-handle-sw { cursor: sw-resize; width: 20px; height: 20px; left: 0; bottom: 0; }
  .react-resizable-handle-w { cursor: w-resize; width: 10px; height: 100%; left: 0; top: 0;  transform: none; background: none;}
  .react-resizable-handle-n { cursor: n-resize; width: 100%; height: 10px; left: 0; top: 0; transform: none; background: none; }
  .react-resizable-handle-ne { cursor: ne-resize; width: 20px; height: 20px; right: 0; top: 0; }
  .react-resizable-handle-nw { cursor: nw-resize; width: 20px; height: 20px; left: 0; top: 0; }

}

.source-preview-wrap{
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  box-sizing: border-box;

  .source-preview-header-wrap{
    cursor: move;
    padding: 0.5em;
    -webkit-padding-start: 1em;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 5px 5px 0 0;
    .source-preview-header{
      align-items: center;
      justify-content: space-between;
    }
  }
  .source-preview-body{
    flex: 1;
    overflow: auto;
    padding: 0 1em;

    iframe{
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
    }
  }
}