.nav-bar-wrapper {
  position: relative;
  width: 100%;
  height: 55px;
  z-index: 10000;
}

.nav-bar {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  background-color: #282c34;
  font-size: 0.7em;
  box-shadow: 0 0 5px #00000099;
  z-index: 10000;

  .nav-bar-logo {
    left: 0;
    width: 80px;
    height: 55px;
    margin: 0 1em;
    //background-image: url(../../static/Logo.svg);
    background-position: 0 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .nav-bar-icon {
    left: 0;
    line-height: 55px;
    padding: 0 1em;
    font-size: 25px;
    color: #efefef;
    cursor: pointer;
    display: flex;
  }

  .menu-label {
    line-height: 1.1em;
    margin: 0 1em;
    font-size: 0.7em;
    display: inline-block;
    vertical-align: sub;
  }

  .nav-bar-icon:hover {
    text-shadow: 0 0 3px 3px white;
  }

  .nav-bar-logo-menu-wrap {
    position: relative;
  }

  .nav-bar-logo-menu {
    display: none;
    position: absolute;
    padding: 0.5em;
    background-color: #efefef;
    z-index: 1000;
    width: 10em;
  }

  .nav-bar-logo-menu-wrap:hover .nav-bar-logo-menu {
    display: block;
  }

  .nav-bar-logout-button {
    cursor: pointer;
    background-color: #DDD;
    padding: 0.25em;
  }

  .nav-bar-logout-button:hover {
    color: #eee;
    background-color: #002c34;
  }

  .title-wrap {
    color: #fbfbfb;
    font-size: 21px;
    line-height: 55px;
    text-align: right;
    font-weight: bold;
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 0.25em;
    padding-left: 1em;
  }

  .title-lang {
    margin-left: 5%;
    white-space: nowrap;
  }
}

.sidebar-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3000;
  height: 100%;
  transition: width 0.15s ease-out;
  -moz-transition: width 0.15s ease-out;
  -ms-transition: width 0.15s ease-out;
  -webkit-transition: width 0.15s ease-out;
  -o-transition: width 0.15s ease-out;
  width: 0;
  overflow: hidden;
  box-shadow: 0 0 5px #444444aa;
}
:dir(ltr) .sidebar-wrap {
    left: 0;
    right: auto;
}
:dir(rtl) .sidebar-wrap {
    right: 0;
    left: auto;
}

.sidebar-open .sidebar-wrap {
  width: 30em;
  max-width: 100%;
  transition: width 0.25s ease-out;
  -moz-transition: width 0.25s ease-out;
  -ms-transition: width 0.25s ease-out;
  -webkit-transition: width 0.25s ease-out;
  -o-transition: width 0.25s ease-out;
}

.sidebar-background {
  background-color: rgba(0, 0, 0, 0.4);
  width: 0;
  height: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 999;
  opacity: 0;
  transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -ms-transition: opacity 1s ease-out;
  -webkit-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
}

.sidebar-open .sidebar-background {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.sidebar {
  background-color: #FAFAFA;
  height: 100%;
  width: 100%;
}

.sidebar-item {
  opacity: 0;
  color: black;
  text-decoration: none;
}

.sidebar-open .sidebar-item {
  opacity: 1;
}

.sidebar-options {
  height: calc(100vh - 55px);
  overflow-y: auto;
}

.sidebar-options .sidebar-item {
  display: block;
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  font-size: 1.25em;
  opacity: 0;
  transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.sidebar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 2.5em;
  background-color: #002c34;;
  color: white;
  font-weight: bold;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 0 5px #000000aa;
}

.sidebar-item.button {
  cursor: pointer;
}

.sidebar-item.button:hover {
  background-color: aliceblue;
  transition: none;
}

.sidebar-item.button:active {
  background-color: aliceblue;
}

.sidebar-item .sidebar-item-ar, .sidebar-item .sidebar-item-he {
  width: 12em;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

@for $i from 2 through 20 {
  .sidebar-options .sidebar-item:nth-child(#{$i}) {
    transition-delay: (0.02s * $i);
    -moz-transition-delay: (0.02s * $i);
    -webkit-transition-delay: (0.02s * $i);
    -o-transition-delay: (0.02s * $i);
  }
}

.sidebar-open .sidebar-options .sidebar-item {
  opacity: 1;
}

@media only screen and (max-device-width: 480px) {
  .sidebar-open .sidebar-wrap {
    width: 100%;
  }
}