.entries-selection-wrap, .article-parsing-wrap{
  height: 70vh;
  width: 100%;
  .frontpage-preview-wrap, .article-preview-wrap{
    height: 100%;
    width: 70%;
    .selector-generator-iframe{
      height: 100%;
      width: 100%;
      border: none;
    }
  }
  .entries-preview-wrap, .article-details-wrap{
    height: 100%;
    width: 30%;
    overflow: auto;
  }
}
